import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~4],
		"/design_system/button": [5],
		"/design_system/markdown": [6],
		"/onboarding": [7],
		"/privacy_policy": [8],
		"/terms_of_service": [9],
		"/third_party_agreement": [10],
		"/waitlist": [11],
		"/workflows/[workflowid]": [12,[2]],
		"/workflows/[workflowid]/checks": [13,[2]],
		"/workflows/[workflowid]/checks/[checkid]": [14,[2]],
		"/workflows/[workflowid]/documents": [15,[2,3]],
		"/workflows/[workflowid]/documents/[documentid]": [16,[2,3]],
		"/workflows/[workflowid]/reports": [17,[2]],
		"/workflows/[workflowid]/settings": [18,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';